import { ref, onMounted, reactive } from "vue";

/**
 * hook：监听某标签鼠标滚轮事件
 * @param {*} domSelector 
 * @returns 
 */
export const useKeyClickListen = (domSelector) => {
  const callback = reactive({
    /** 鼠标滚轮下滚的callback */
    pagedownCb: null,
    /** 鼠标滚轮上滚的callback */
    pageupCb: null
  })

  onMounted(() => {
    document.querySelector(domSelector).addEventListener("keydown", e => {
      if (e.key === 'ArrowDown') {
        callback.pagedownCb && callback.pagedownCb()
      }
      else if (e.key === 'ArrowUp') {
        callback.pageupCb && callback.pageupCb()
      }
    })
  })

  return {
    callback
  }
}